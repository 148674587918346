import { useHead } from "@vueuse/head"; // ✅ 引入 meta 工具
import api from "@/assets/js/api"; // 引入API服務

export default {
  name: "HomeView",

  // ✅ SEO 標籤設定區塊
  created() {
    useHead({
      title: "祐強醫療儀器｜專業醫療器材與設備解決方案",
      meta: [
        {
          name: "description",
          content:
            "祐強醫療提供優質的醫療器材、設備安裝與維護、技術支援與快速物流服務，是您醫療機構的最佳夥伴。",
        },
        {
          name: "keywords",
          content:
            "YoChung Medical, 祐強, 醫療器材, 醫療設備, 醫療供應商, 醫療維修, 醫療租賃, 醫療技術, 醫療物流, 醫療服務, 醫療解決方案",
        },
        {
          property: "og:title",
          content: "祐強醫療儀器｜專業醫療器材與設備解決方案",
        },
        {
          property: "og:description",
          content:
            "專業醫療設備供應與技術支援，打造高品質、高效率的醫療環境，立即了解祐強醫療的服務與產品。",
        },
        {
          property: "og:image",
          content: "https://christylove.blob.core.windows.net/image/favicon.ico", // 🟡 這裡換成你要分享的代表圖片網址
        },
      ],
    });
  },
  data() {
    return {
      // 輪播圖數據
      currentSlide: 0,
      carouselSlides: [
        {
          image: require("@/assets/image/banner1.jpg"),
          title: "專業醫療儀器供應商",
          description: "提供全方位的醫療設備與解決方案，滿足各種臨床需求",
          buttonText: "了解更多",
          link: "/about",
        },
        {
          image: require("@/assets/image/banner2.png"),
          title: "高品質醫療器材",
          description: "引進國際知名品牌，提供最先進的醫療技術",
          buttonText: "瀏覽產品",
          link: "/products",
        },
        {
          image: require("@/assets/image/banner3.png"),
          title: "專業技術支援",
          description: "完善的售後服務與技術支持，確保設備正常運作",
          buttonText: "聯絡我們",
          link: "/contact",
        },
      ],

      // 熱門產品輪播數據和控制
      productCurrentSlide: 0,
      productSlideWidth: 0,
      productItemsPerSlide: 8, // 一次顯示的卡片數量，會根據視窗大小調整
      productTouchStartX: 0,
      productTouchEndX: 0,
      featuredProducts: [],
      loadingProducts: true, // 加載狀態
      errorLoadingProducts: false, // 錯誤狀態

      // 客戶評價數據
      currentTestimonial: 0,
      testimonials: [
        {
          id: 1,
          content:
            "祐強醫療儀器提供的設備品質超群，售後服務也非常好。我們診所自從使用了他們的設備，工作效率大大提高，病患也很滿意。",
          name: "李明德 醫師",
          title: "院長",
          company: "仁愛診所",
          avatar: "https://via.placeholder.com/80x80/E8F0FE/333333?text=李醫師",
        },
        {
          id: 2,
          content:
            "作為一家新成立的診所，我們需要可靠的設備供應商。祐強不僅提供高品質的產品，還給予我們許多專業建議，幫助我們節省了不少成本。",
          name: "張雅婷",
          title: "行政主管",
          company: "康和醫療中心",
          avatar: "https://via.placeholder.com/80x80/E8F0FE/333333?text=張主管",
        },
        {
          id: 3,
          content:
            "祐強的技術支援團隊反應迅速、專業度高，每次設備出現問題都能在最短時間內解決，減少了我們的營運中斷時間。",
          name: "王建國",
          title: "技術總監",
          company: "大安醫院",
          avatar: "https://via.placeholder.com/80x80/E8F0FE/333333?text=王總監",
        },
      ],

      // 合作品牌數據
      brands: [
        {
          id: 1,
          name: "BOWA",
          logo: require("@/assets/image/BOWA.png"),
        },
        {
          id: 2,
          name: "BOWA",
          logo: require("@/assets/image/BOWA.png"),
        },
        {
          id: 3,
          name: "BOWA",
          logo: require("@/assets/image/BOWA.png"),
        },
        {
          id: 4,
          name: "BOWA",
          logo: require("@/assets/image/BOWA.png"),
        },
        {
          id: 5,
          name: "BOWA",
          logo: require("@/assets/image/BOWA.png"),
        },
        {
          id: 6,
          name: "BOWA",
          logo: require("@/assets/image/BOWA.png"),
        },
      ],

      // 電子報訂閱
      email: "",
    };
  },
  mounted() {
    // 自動輪播
    this.startCarousel();
    this.startTestimonialSlider();

    // 獲取熱門產品數據
    this.fetchTopProducts();

    // 產品輪播初始化
    this.calculateProductSlideWidth();
    this.updateProductItemsPerSlide();

    // 監聽視窗調整大小事件
    window.addEventListener("resize", this.handleProductResize);

    // 添加觸摸滑動支持（適用於行動裝置）
    const productCarousel = document.querySelector(".product-carousel");
    if (productCarousel) {
      productCarousel.addEventListener(
        "touchstart",
        this.handleProductTouchStart,
        false
      );
      productCarousel.addEventListener(
        "touchend",
        this.handleProductTouchEnd,
        false
      );
    }

    // 假裝加載 AOS 動畫庫
    console.log("AOS animations would be initialized here");
    // 在實際項目中，您可以使用:
    // import AOS from 'aos';
    // AOS.init({
    //   duration: 1000,
    //   once: true,
    //   offset: 100
    // });
  },
  beforeUnmount() {
    // 清除計時器
    clearInterval(this.carouselInterval);
    clearInterval(this.testimonialInterval);

    // 清除事件監聽器
    window.removeEventListener("resize", this.handleProductResize);

    const productCarousel = document.querySelector(".product-carousel");
    if (productCarousel) {
      productCarousel.removeEventListener(
        "touchstart",
        this.handleProductTouchStart
      );
      productCarousel.removeEventListener(
        "touchend",
        this.handleProductTouchEnd
      );
    }
  },
  methods: {
    handleImageError(e) {
      e.target.src = require("@/assets/image/error.jpg"); // 如果圖片載入失敗，顯示預設圖片
    },

    async fetchTopProducts() {
      this.loadingProducts = true;
      this.errorLoadingProducts = false;
    
      try {
        const response = await api.get("/products/top");
    
        // ✅ 直接轉換成前端要用的欄位格式
        this.featuredProducts = response.data.map(item => ({
          id: item.Id,
          name: item.Name,
          image: item.ImageUrl,
          shortDescription: item.Description,
          category: item.Category,
        }));
      } catch (error) {
        console.error("❌ 無法獲取熱門產品:", error);
        this.errorLoadingProducts = true;
        this.featuredProducts = []; // 不用假資料，直接顯示空畫面或提示
      } finally {
        this.loadingProducts = false;
        this.$nextTick(() => {
          this.calculateProductSlideWidth();
          this.updateProductItemsPerSlide();
        });
      }
    },
    

    // 橫幅輪播控制
    startCarousel() {
      this.carouselInterval = setInterval(() => {
        this.nextSlide();
      }, 5000); // 每5秒切換一次
    },
    prevSlide() {
      this.currentSlide =
        (this.currentSlide - 1 + this.carouselSlides.length) %
        this.carouselSlides.length;
    },
    nextSlide() {
      this.currentSlide = (this.currentSlide + 1) % this.carouselSlides.length;
    },
    setSlide(index) {
      this.currentSlide = index;
    },

    // 產品輪播控制方法
    calculateProductSlideWidth() {
      const card = document.querySelector(".product-card");
      if (card) {
        // 卡片寬度 + 左右margin
        this.productSlideWidth = card.offsetWidth + 20;
      }
    },

    updateProductItemsPerSlide() {
      if (window.innerWidth >= 992) {
        this.productItemsPerSlide = 3; // 大螢幕顯示3個
      } else if (window.innerWidth >= 768) {
        this.productItemsPerSlide = 2; // 平板顯示2個
      } else {
        this.productItemsPerSlide = 1; // 手機顯示1個
      }
    },

    handleProductResize() {
      this.calculateProductSlideWidth();
      this.updateProductItemsPerSlide();

      // 確保當前幻燈片索引有效
      const maxSlide = Math.max(
        0,
        this.featuredProducts.length - this.productItemsPerSlide
      );
      if (this.productCurrentSlide > maxSlide) {
        this.productCurrentSlide = maxSlide;
      }
    },

    prevProduct() {
      if (this.productCurrentSlide > 0) {
        this.productCurrentSlide--;
      } else {
        // 循環到最後
        this.productCurrentSlide = Math.max(
          0,
          this.featuredProducts.length - this.productItemsPerSlide
        );
      }
    },

    nextProduct() {
      if (
        this.productCurrentSlide <
        this.featuredProducts.length - this.productItemsPerSlide
      ) {
        this.productCurrentSlide++;
      } else {
        // 循環到最前
        this.productCurrentSlide = 0;
      }
    },

    goToProductSlide(index) {
      this.productCurrentSlide = index;
    },

    handleProductTouchStart(event) {
      this.productTouchStartX = event.changedTouches[0].screenX;
    },

    handleProductTouchEnd(event) {
      this.productTouchEndX = event.changedTouches[0].screenX;
      this.handleProductSwipe();
    },

    handleProductSwipe() {
      const swipeThreshold = 50; // 滑動閾值

      if (this.productTouchEndX + swipeThreshold < this.productTouchStartX) {
        // 向左滑動 -> 下一張
        this.nextProduct();
      } else if (
        this.productTouchEndX >
        this.productTouchStartX + swipeThreshold
      ) {
        // 向右滑動 -> 上一張
        this.prevProduct();
      }
    },

    // 客戶評價輪播控制
    startTestimonialSlider() {
      this.testimonialInterval = setInterval(() => {
        this.nextTestimonial();
      }, 6000); // 每6秒切換一次
    },
    prevTestimonial() {
      this.currentTestimonial =
        (this.currentTestimonial - 1 + this.testimonials.length) %
        this.testimonials.length;
    },
    nextTestimonial() {
      this.currentTestimonial =
        (this.currentTestimonial + 1) % this.testimonials.length;
    },
    setTestimonial(index) {
      this.currentTestimonial = index;
    },

    // 電子報訂閱處理
    subscribeNewsletter() {
      alert(`感謝您的訂閱！我們已收到您的電子郵件: ${this.email}`);
      this.email = "";
      // 在實際項目中，這裡會發送 API 請求到後端處理訂閱
    },
  },
};
