<template>
  <div class="home-page">
    <!-- 主橫幅輪播 -->
    <section class="hero-carousel">
      <div class="carousel-container">
        <div
          class="carousel-slide"
          v-for="(slide, index) in carouselSlides"
          :key="index"
          v-show="currentSlide === index"
        >
          <img :src="slide.image" :alt="slide.title" />
          <div class="overlay"></div>
          <!-- 新增的黑色遮罩層 -->
          <div class="carousel-content">
            <h1>{{ slide.title }}</h1>
            <p>{{ slide.description }}</p>
            <router-link :to="slide.link" class="btn-primary">{{
              slide.buttonText
            }}</router-link>
          </div>
        </div>

        <div class="carousel-controls">
          <button @click="prevSlide" class="prev-btn">
            <i class="fas fa-chevron-left"></i>
          </button>
          <div class="carousel-dots">
            <span
              v-for="(slide, index) in carouselSlides"
              :key="index"
              :class="{ active: currentSlide === index }"
              @click="setSlide(index)"
            ></span>
          </div>
          <button @click="nextSlide" class="next-btn">
            <i class="fas fa-chevron-right"></i>
          </button>
        </div>
      </div>
    </section>

    <!-- 簡介區塊 -->
    <section class="intro-section">
      <div class="container">
        <div class="intro-flex">
          <div class="intro-content" data-aos="fade-right">
            <h2 class="section-title">醫療器材專家</h2>
            <p>
              祐強醫療儀器有限公司擁有超過 20
              年的專業經驗，我們致力於提供醫療機構最優質的設備與服務。無論是公立醫院、私人診所還是專業實驗室，我們都能提供符合您需求的解決方案。
            </p>
            <div class="feature-boxes">
              <div class="feature-box">
                <i class="fas fa-medal"></i>
                <div>
                  <h3>高品質保證</h3>
                  <p>所有產品均經過嚴格品質控管</p>
                </div>
              </div>
              <div class="feature-box">
                <i class="fas fa-headset"></i>
                <div>
                  <h3>專業技術支援</h3>
                  <p>售前售後全程技術諮詢</p>
                </div>
              </div>
              <div class="feature-box">
                <i class="fas fa-truck"></i>
                <div>
                  <h3>快速配送</h3>
                  <p>全台灣快速送達與安裝服務</p>
                </div>
              </div>
              <div class="feature-box">
                <i class="fas fa-hand-holding-medical"></i>
                <div>
                  <h3>設備租賃服務</h3>
                  <p>全台灣快速送達與安裝服務</p>
                </div>
              </div>
            </div>
            <router-link to="/about" class="btn-outline"
              >了解更多關於我們</router-link
            >
          </div>
          <div class="intro-image" data-aos="fade-left">
            <img
              :src="require('@/assets/image/about1.png')"
              alt="祐強醫療儀器 - 專業醫療設備供應商"
            />
          </div>
        </div>
      </div>
    </section>

    <!-- 熱門產品區塊 - 水平輪播版 -->
    <section class="featured-products">
      <div class="container">
        <h2 class="section-title text-center" data-aos="fade-up">熱門產品</h2>
        <p class="section-subtitle text-center" data-aos="fade-up">
          探索我們精選的高品質醫療器材
        </p>

        <!-- 加載狀態顯示 -->
        <div v-if="loadingProducts" class="text-center py-4">
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          <p class="mt-2">正在加載熱門產品...</p>
        </div>

        <!-- 錯誤狀態顯示 -->
        <div
          v-else-if="errorLoadingProducts"
          class="alert alert-warning text-center"
          role="alert"
        >
          無法加載熱門產品數據，請稍後再試。
        </div>

        <!-- 產品輪播 -->
        <div v-else class="product-carousel-container" data-aos="fade-up">
          <!-- 左側箭頭按鈕 -->
          <button
            class="carousel-arrow prev-arrow"
            @click="prevProduct"
            :disabled="featuredProducts.length <= productItemsPerSlide"
          >
            <i class="fas fa-chevron-left"></i>
          </button>

          <!-- 產品輪播區域 -->
          <div class="product-carousel">
            <div
              class="product-carousel-track"
              :style="{
                transform: `translateX(-${
                  productCurrentSlide * productSlideWidth
                }px)`,
              }"
            >
              <div
                class="product-card"
                v-for="product in featuredProducts"
                :key="product.id"
              >
                <div class="product-image">
                  <img
                    :src="product.image"
                    :alt="product.name"
                    @error="handleImageError"
                  />
                  <div class="product-overlay">
                    <router-link
                      :to="`/products?id=${product.id}`"
                      class="view-details"
                      >查看詳情</router-link
                    >
                  </div>
                </div>
                <div class="product-info">
                  <h3>{{ product.name }}</h3>
                  <p>{{ product.shortDescription }}</p>
                  <div class="product-meta">
                    <span class="product-category">{{ product.category }}</span>
                    <router-link
                      :to="`/products/${product.id}`"
                      class="product-link"
                    >
                      了解更多 <i class="fas fa-arrow-right"></i>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- 右側箭頭按鈕 -->
          <button
            class="carousel-arrow next-arrow"
            @click="nextProduct"
            :disabled="featuredProducts.length <= productItemsPerSlide"
          >
            <i class="fas fa-chevron-right"></i>
          </button>
        </div>

        <!-- 指示點 -->
        <div
          v-if="
            !loadingProducts &&
            !errorLoadingProducts &&
            featuredProducts.length > productItemsPerSlide
          "
          class="carousel-indicators"
        >
          <span
            v-for="(_, index) in Math.ceil(
              featuredProducts.length / productItemsPerSlide
            )"
            :key="index"
            class="indicator"
            :class="{
              active:
                Math.floor(productCurrentSlide / productItemsPerSlide) ===
                index,
            }"
            @click="goToProductSlide(index * productItemsPerSlide)"
          >
          </span>
        </div>

        <div class="text-center view-all-products" data-aos="fade-up">
          <router-link to="/products" class="btn-primary"
            >查看所有產品</router-link
          >
        </div>
      </div>
    </section>

    <!-- 品牌與評價並排區塊 -->
    <section class="brands-testimonials-section">
      <div class="container">
        <div class="flex-container">
          <!-- 左側：品牌與認證區塊 -->
          <div class="brands-container" data-aos="fade-right">
            <h2 class="section-title">合作品牌與認證</h2>
            <div class="brands-grid">
              <div class="brand-logo" v-for="brand in brands" :key="brand.id">
                <img :src="brand.logo" :alt="brand.name" />
              </div>
            </div>
          </div>

          <!-- 右側：客戶評價區塊 -->
          <div class="testimonials-container" data-aos="fade-left">
            <h2 class="section-title">醫師使用器材推薦</h2>
            <div class="testimonials-slider">
              <div
                class="testimonial-slide"
                v-for="(testimonial, index) in testimonials"
                :key="testimonial.id"
                v-show="currentTestimonial === index"
              >
                <div class="testimonial-content">
                  <div class="quote-icon">
                    <i class="fas fa-quote-left"></i>
                  </div>
                  <p>{{ testimonial.content }}</p>
                  <div class="testimonial-author">
                    <img :src="testimonial.avatar" :alt="testimonial.name" />
                    <div>
                      <h4>{{ testimonial.name }}</h4>
                      <p>{{ testimonial.title }}, {{ testimonial.company }}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="testimonial-controls">
                <button @click="prevTestimonial">
                  <i class="fas fa-chevron-left"></i>
                </button>
                <div class="testimonial-dots">
                  <span
                    v-for="(testimonial, index) in testimonials"
                    :key="index"
                    :class="{ active: currentTestimonial === index }"
                    @click="setTestimonial(index)"
                  ></span>
                </div>
                <button @click="nextTestimonial">
                  <i class="fas fa-chevron-right"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<style src="@/assets/css/home.css"></style>
<script src="@/assets/js/home.js"></script>
